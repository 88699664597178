import { Component, Vue } from 'vue-property-decorator';
import { SnackbarAction } from '@/store/snackbar';
import { SnackbarType } from '@/models/enums/snackbar';

@Component
export default class BaseSnackbar extends Vue {
  get color(): string {
    switch (this.$store.state.snackbar.type) {
      case SnackbarType.Error:
        return 'error';
      case SnackbarType.Success:
        return 'success';
      default:
        return 'info';
    }
  }

  get isOpen(): boolean {
    return this.$store.state.snackbar.isOpen;
  }

  set isOpen(isOpen: boolean) {
    if (!isOpen) {
      this.$store.dispatch(SnackbarAction.Close);
    }
  }

  get text(): string {
    return this.$store.state.snackbar.text;
  }

  get timeout(): number {
    return this.$store.state.snackbar.timeout;
  }

  close(): void {
    this.isOpen = false;
  }
}
