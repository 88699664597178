<template>
  <div id="app">
    <router-view/>
    <error/>
    <modal-message/>
  </div>
</template>

<script>
import Error from '@/components/Error.vue';
import ModalMessage from '@/pages/ModalMessage.vue';
import LiveChat from '@/components/LiveChat.vue';
import { themes } from '@/data/themeColors';
import BaseSnackbar from '@/components/common/BaseSnackbar/BaseSnackbar.vue';
import { companies } from '@/data/companyByCSSName';

const App = {
  name: 'app',
  computed: {
    buttonStyles() {
      return this.$store.state.siteInfo.buttonChatStyle;
    },
    cssTheme() {
      return this.$store.state.siteInfo.siteCssInfoResponse;
    },
  },

  methods: {
    setDocumentTitleAndFavIcon(themeName) {
      const name = themeName || 'default';
      document.title = companies[name] || 'Wifinity';

      if (name === 'parkconnect') {
        this.addFavIcon(require('@/assets/parkconnect/favicon-parkdean-resort.svg'));
      }
      if (name === 'optify') {
        this.addFavIcon(require('@/assets/optify/favicon-32x32.png'));
      }
      if (name === 'fujitsu') {
        this.addFavIcon(require('@/assets/fujitsu/favicon.png'));
      }
    },
    addFavIcon(favIconURL) {
      try {
        const favicon = document.getElementById('favicon');
        if (!favicon) return;
        favicon.setAttribute('href', favIconURL);
      } catch (e) {
        console.warn(e);
      }
    },
  },

  watch: {
    // Setting vuetify theme colors and document title
    cssTheme(theme) {
      if (!theme.name || theme.name === 'default') return;

      this.setDocumentTitleAndFavIcon(theme.name);

      const foundedTheme = themes[theme.name];
      if (!foundedTheme) return;

      for (const [color, val] of Object.entries(foundedTheme)) {
        this.$vuetify.theme.themes.light[color] = val;
      }
    },
  },

  components: {
    BaseSnackbar,
    Error,
    ModalMessage,
    LiveChat,
  },
};

export default App;
</script>

<style lang="scss"
       src="@/styles/portal/base.scss"/>
<style src="./styles/user-styles.css" />
<style lang="scss">
@import '~@/styles/vuetify/vuetify.scss';

::part(acsb-trigger) {
  z-index: 10 !important;
}

.content {
  min-height: 100vh;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

@media screen and (max-width:749px) {
  .page-content .title-bar {
    width: 100% !important;
    position: relative !important;
  }
  .view-container .page-content .simple-card.card-my-account {
    margin-top: 0;
    height: 465px;
  }
}

@media screen and (max-width:480px) {
  .page-content .title-bar {
    margin: auto;
    text-align: center;
  }
}

#wg-fr,
#wg-zh,
#wg-cy,
#wg-ar,
#wg-es,
#wg-en {
  right: 0;
}

.weglot-container {
  z-index: 1 !important;
}

.weglot_here {
  position: absolute;
  left: 120px;
  top: -50px;
}

.wgcurrent {
  @include breakpoint(small only) {
    border: none !important;
    background: #f5f5f5;
  }

  a {
    font-size: 0 !important;
  }
}

.weglot_switcher {
  ul {
    border: none !important;
    @include breakpoint(small only) {
      background: #f5f5f5 !important;
    }
  }
  .wgcurrent a {
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .weglot-container {
    left: 12px;
  }
}
</style>
<style lang="scss"
       src="./styles/base.scss" />
